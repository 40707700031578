<template>
  <div
    v-if="alert.show"
    class="w-full transform fixed top-0 transition delay-150 duration-300 ease-in-out"
    :class="alert.show ? 'translate-y-2' : '-translate-y-20'"
  >
    <div class="rounded-md p-4 w-1/2 left-1/2 m-auto" :class="status[alert.type].bg">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg
            class="h-5 w-5"
            :class="status[alert.type].light"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div class="ml-3">
          <p class="text-sm font-medium" :class="status[alert.type].dark">{{ alert.text }}</p>
        </div>
        <div class="ml-auto pl-3">
          <div class="-mx-1.5 -my-1.5">
            <button
              @click.prevent="close"
              type="button"
              class="inline-flex rounded-md p-1.5 focus:outline-none"
              :class="`${status[alert.type].btn} ${status[alert.type].bg}`"
            >
              <span class="sr-only">Dismiss</span>
              <svg
                class="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: ['value'],
  data() {
    return {
      status: {
        success: {
          light: 'text-green-400',
          bg: 'bg-green-50',
          dark: 'text-green-800',
          btn: 'text-green-500 hover:bg-green-100',
        },
        warning: {
          light: 'text-yellow-400',
          bg: 'bg-yellow-50',
          dark: 'text-yellow-800',
          btn: 'text-yellow-500 hover:bg-yellow-100',
        },
        danger: {
          light: 'text-red-400',
          bg: 'bg-red-50',
          dark: 'text-red-800',
          btn: 'text-red-500 hover:bg-red-100',
        },
      },
    }
  },
  methods: {
    close() {
      this.setAlert({ message: null, show: false })
    },
  },
}
</script>

<style></style>
